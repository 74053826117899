


.landingPage {
  grid-row: 1/4;
  grid-column: 1/4;
  border-radius: 3px;
  height: 100%;

  font-family: "Montserrat", sans-serif;
  display: grid;

  grid-template-columns: 1fr;
  grid-template-rows: 3em auto;
  scroll-behavior: smooth;
  overflow:scroll !important;

}

.landingPage > *{
  /* overflow:scroll !important; */
}

h1 {
  font-size: 3em;
  font-weight: bold;
  color: #8d448b;
  /* text-align: start; */
}

h2 {
  margin: 0;
  font-size: 1em;
}

p {
  color: gray;
}

a {
  color: #8d448b;
}

a:hover {
  color: #b080af;
  text-decoration: none;
}

.logo {
  height: 1.5em;
  padding-right: 0.5em;
}

.primary-color {
  background-color: #f6f8fb !important;
}

.secondary-color {
  background-color: #fefefe !important;
}

.navBar {
  padding-top: 1em;
  padding-bottom: 2.5em;
  /* margin-bottom: 1em; */
  display: grid;
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  grid-template-columns: 1fr 1fr 1fr;
  border-bottom: 1px rgb(228, 228, 228) solid;
  align-items: center;
}

.navBarTop {
  background-color: #f6f8fb !important;
  transition: 1s;
}

.navBarMoved {
  background-color: #f6f8fb !important;
  transition: 1s;
}

.homeLogo {
  display: flex;
  justify-self: start;
  margin-left: 1em;
  /* justify-content: space-evenly; */
}

.loginWrap {
  justify-self: end;
  align-self: flex-start;
  margin-right: 5em;
  border-radius: 8px;
  padding-right: 1em;
  padding-left: 1em;
  padding-top: 0.2em;
  font-weight: bold;
  font-size: 1em;
  color: #8d448b;
  cursor: pointer;
  border: 1px solid #8d448b;
}


.loginWrap:hover{
  background-color: #8d448b;
  color: white;
}

.emailInput {
  color: #8d448b;
  margin-right: 10px;
  border: 1px solid #8d448b;
  border-radius: 5px;
}

.loginButton:hover {
  font-weight: bold;
  font-size: 1em;
  color: rgb(255, 255, 255);
  background-color: #8d448b;
  border: 1px solid #f1f1f1;
}

.loginButton > a {
  color: #8d448b;
}

.loginButton > a:hover {
  color: #f1f1f1;
}

.blockWrap {
  height: auto;
  padding-top: 3.5em;
  padding-bottom: 0.5em;
}

/* Home */

#homeWrap {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

#homeLeftWrap {
  display: flex;
  flex-direction: column;
  padding: 3em;
  padding-left: 5em;
  align-items: flex-start;
}

#homeRightWrap {
  justify-self: center;
  align-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;
 background-color:#f6f8fb;
}

.socialMedia {
  padding-top: 1em;
}

.missionWrap {
  text-align: start;
  padding-bottom: 0.5em;
  align-self: start;
}

.menu {
  display: flex;
  justify-content: space-evenly;
}

.wailistWrap {
  display: flex;
  flex-direction: row;
}

#usedByWrap {
  padding-top: 2em;
  color: grey;
}

.panda-container{
 background-color:#f6f8fb;
}

.panda-container>*{
  transform: scale(1.9);
  margin-top: 30px;
  transition: transform 2.5s ease-in-out; 
}
.panda-container>*:hover{
  transform: scale(2.1);
  box-shadow: none;
  transition: transform 1s ease-in-out; 

}
#pandaImage {
  height: 18em;
  border-radius: 7px;
  cursor: pointer;
  transition: 0.5s;
  /* height: 140px; */
}

#pandaImage:hover {
  /* box-shadow: 3px 3px 10px #8d448b; */
  transition: 0.5s;
}
#instagramIcon {
  background-color:#f6f8fb;

}
#instagamIcon > * {
  transition: 0.3s;
  fill: #aeb4be;
}

#instagamIcon > *:hover {
  fill: #8d448b;
  transition: 0.3s;
  /* box-shadow: 3px 3px 10px #8d448b; */
  /* background-color: transparent; */
}

/* FEATURES */

.featuresWrap {
  justify-self: center;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.featureContentWrap {
  padding: 5em;
  padding-top: 2em;
  padding-bottom: 1em;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
}

.eachFeatureWrap {
  display: grid;
  grid-template-rows: auto 1fr auto;
}

.eachFeatureWrap > h3 {
  font-size: 1.5em;
  font-weight: bold;
  padding: 0;
  margin: 0;
  padding-bottom: 0.3em;
}

.featureImage {
  height: 150px;
  justify-self: center;
  align-self: center;
  margin-bottom: 1em;
}

/* DEMO */

#demoVideo {
  cursor: pointer;
  border-radius: 10px;
  box-shadow: 3px 5px 10px #8d448b;
  width: auto;
  margin-bottom: 3em;
  transition: 0.5s;
}

#demoVideo:hover {
  cursor: pointer;
  border-radius: 10px;
  box-shadow: 5px 8px 10px #8d448b;
  width: auto;
  margin-bottom: 3em;
  transition: 0.5s;
}

/* About */
#aboutWrap {
  display: flex;
  flex-direction: column;
  padding-left: 10em;
  padding-right: 10em;
}

#aboutWrap > h2 {
  font-size: 2em;
  color: #8d448b;
  font-weight: bold;
  padding-top: 1em;
}
#aboutWrap > h3 {
  font-size: 1.5em;
  padding-right: 5em;
  padding-left: 5em;
  padding-top: 0.3em;
}

@media only screen and (max-width: 900px) {
  .landingPage {
    grid-template-rows: 2em auto;
  }

  h1 {
    font-size: 2em;
  }

  .navBar {
    padding-top: 1em;
    padding-bottom: 2.5em;
    /* margin-bottom: 1em; */
    display: grid;
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 0;
    grid-template-columns: 1fr 1fr;
    border-bottom: 1px rgb(228, 228, 228) solid;
    align-items: center;
  }

  .blockWrap {
    height: auto;
    padding-top: 3em;
    padding-bottom: 0.5em;
  }

  #homeWrap{
    display: flex;
    justify-content: center;
    align-items: center;
    width:100vw; 
  }

  #homeLeftWrap {
    width:100vw; 
    align-self:center;
    align-items:center;
    justify-content:center;
  }

  #homeLeftWrap > * {
    /* text-align:center; */
  }

  .wailistWrap {
    display: flex;
    flex-direction: column;
    /* align-self: center; */
    /* padding-left: 7em; */
  }

  .waitlistDescription {
    /* align-self: center; */
    /* padding-left: 7em; */
    /* padding-right: 7em; */
    /* text-align: start; */
  }

  #usedByWrap {
    padding-top: 0;
    /* text-align: start; */
    /* align-self: center; */
  }

  #emailWarning {
    /* text-align: start; */
    /* align-self: center; */
  }

  #pandaImage {
    width: 300px;
    height: 200px;
    cursor: pointer;
  }

  .menu {
    display: none;
  }

  .loginWrap {
    margin-right: 1em;
  }

  #homeWrap {
    display: flex;
    flex-direction: column;
  }

  .featureContentWrap {
    padding: 0em;
    padding-top: 2em;
    padding-bottom: 1em;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
  }

  /* About */
  #aboutWrap {
    display: flex;
    flex-direction: column;
    padding-left: 1em;
    padding-right: 1em;
  }

  #aboutWrap > h2 {
    font-size: 2em;
    color: #8d448b;
    font-weight: bold;
    padding-top: 1em;
  }
  #aboutWrap > h3 {
    font-size: 1.3em;
    padding-right: 1em;
    padding-left: 1em;
    padding-top: 0.3em;
  }

  /* DEMO */

  #demoVideo {
    width: 300px;
  }

  #demoVideo:hover {
    width: 300px;
    cursor: pointer;
    border-radius: 10px;
    box-shadow: 5px 8px 10px #8d448b;
    margin-bottom: 3em;
    transition: 0.5s;
  }
}
