@media screen and (max-width:1100px){
  .Modal-wrap {
    z-index: 100;
    height: 100vh;
    width: 80vw;
  }

  .modalDialog{
    visibility: transparent;
    background-color:rgba(0,0,0,0);
    height: 100vh;
    width: 100vw;
  }

  .Modal-padding {
    display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px
  }

}

.Modal-wrap {
  position: relative;
  z-index: 100;
  justify-self: center;
  align-self: center;
  text-align: start;
  height: 100%;
  max-height: 300px;
  width: 40%;
}

.modalDialog{
  visibility: transparent;
  background-color:rgba(0,0,0,0);
  width: 72%;
  margin-left: 14%;
}

.start {
  font-family: "Lato";
  font-weight: 400;
}

.Modal-padding {
  display: grid;
  grid-template-columns: 1fr 1fr;
  max-width: 900px;
}

.Modal-greet-welcome {
  color: #000000;
  font-size: 30px;
  font-weight: bold;
  padding-left: 5%;
}

.whatsNew{
  font-size: 14px;
  width: 80%;
  font-family: Arial, Helvetica, sans-serif;
}

.Modal-greet-hi {
  color: #000000;
  font-size: 25px;
  font-weight: bold;
  padding-left: 40%;
  padding-top: 5%;
  padding-bottom: 0%;
}

.Modal-form-wrap {
  border: 1px solid #dbecf7;
  border-radius: 15px;
  box-shadow: 3px 0px 3px rgb(184, 201, 221);
  padding: 5%;
  padding-top: 1%;
  height: 100%;
  width: 100%;
  margin-bottom: 25%;
}

.Modal-guideline-wrap {
  border: 1px solid #dbecf7;
  box-shadow: 0px 3px 3px 3px rgb(184, 201, 221);
  border-radius: 15px;
  padding: 5%;
  height: 100%;
  width: 100%;
  margin-bottom: 25%;
}

.guideLineTitle {
  font-weight: bold;
  text-decoration: underline;
  color: #75acd1;
  padding-left: 25%;
  font-size: 20px;
}


.submit {
  margin-left: 35%;
  font-size: 20px;
  border: #6696b4 1px;
  color: #4a718b;
  background: #dbecf7;
  border-radius: 8px;
  padding: 4px 8px 4px 8px;
}

.submit:hover {
  background: #3b6572;
}

select {
  font-size: 14px;
  width: 80%;
  font-family: "Lato", Helvetica, sans-serif;
}

