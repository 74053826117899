.meetings{
	display: flex;
	flex-flow: column wrap;
	justify-content: space-between;
	align-items:center;
	width: 665px;
	margin-bottom:30px;
}

.meeting-list{
	display: flex;
	flex-direction: column;
	align-items:flex-start;
	width:100%;
	padding: 0.6rem 0;
}

.no-meeting-text{
	font-family: lato;
	font-size: 1rem;
	color: rgb(195, 195, 195);
	align-self: center;
	letter-spacing: .1rem;
	/* transform: rotate(.5deg); */
	/* font-style: italic; */
	/* text-decoration: underline; */
}

.schedule-meeting-button{
	width:200px;
	border-radius: 7.5px;
	/* margin-left */
}

.expand-button{
	cursor: pointer;
}

.meeting-title-container{
	display:flex;
	align-items: center;
	flex-basis:50%;
}

.meeting-card{
	background-color: white;
	margin: 10px 0;
	padding: 15px 5px;
	width: 665px;
	height: 100px;
	border-radius: 5px;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
	justify-content: space-between;
	display:flex;
	align-items: center;
}

.meeting-title-text{
	flex-basis:100%;
	font-size:1.1rem;
	line-height: 1.5;
	max-width: 300px;
	word-break: break-word;
	
}

.meeting-card:hover{
	filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.meeting-left-column, .meeting-right-column{
	display:flex;
	flex-flow: row;
	align-items:center;

}

.meeting-left-column{
	flex-basis: 55%;

}
.meeting-right-column{
	justify-content: flex-end;
	flex-basis: 45%;
	/* justify-content:space-between; */

}

.meetingTitle{
	display:flex;
	flex-flow: column;
	justify-content: center;
	align-self:center;
	height: 100%;
	
}

.live-div{
	background-color: red;
	color: rgb(255, 255, 255);
	justify-items: center;
	align-items: center;
	text-align:center;
	font-size: 0.875rem;
	padding:0.25rem 0.5rem;
	border-radius: 4px;
	font-family: "Lato";
	max-width:50px;
	/* flex-basis: 15%; */
}

.meeting-options-div{
	display: flex;
	flex-direction:row;
	align-items:center;
}
.options-icon{
	width:25px;
	/* height:10px; */
	/* border-radius:12.5px; */
}
.options-icon:hover{
	cursor: pointer;
	/* background-color: rgb(216, 216, 216); */
}

.description-title{
	color: "#f5f5f9";
	margin: 0px;
	font-weight: 600;
}

.description-text{
	font-family:"Lato";
}

.meeting-description{
	width: 250px;
	white-space: nowrap;
	text-overflow:ellipsis;
	/* display:block; */
	overflow:hidden;
	flex-basis:40%;
	text-align:left;
	font-family: "Lato";
	font-size: 0.8rem;
	font-weight: 300;
	padding: 0 10px;
}

.meeting-date{
	flex-basis: 50%;
	justify-content: flex-end;
	display:flex;
	flex-direction:column;
	align-items: flex-end;
}

.profileImage{
	cursor:pointer;
}

#meeting-scheduler-title, #meeting-scheduler-description{
	width: 350px;
}

#date-picker-dialog,#time-picker{
	width: 300px;
}

#meeting-scheduler-duration{
	width: 160px;
}

#meeting-scheduler-duration-min{
	width: 150px;
}

.visibility-div{
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 24px;
}

.errorWarning{
	padding: 5px;
}


.participants{
	margin: 0px 10px;
	padding: 15px 10px;
}

.participants-title{
	margin-bottom: 5px;
	font-size: 1.2rem;
	font-weight: 700;
	font-family: "Lato";
	border-bottom: 1px grey solid;
}

.participants-list{
	display: flex;
	flex-direction: column;
	font-family: "Lato";
	margin-top: 5px;
}

.participant-card{
	display:flex;
	flex-direction: row;
	padding: 5px 0;
	align-items: center;
}

.participant-email{
	font-weight:300;
}

.participant-card-text{
	display: flex;
	flex-direction: column;
	padding: 5px;
	font-weight: 600;
}

@media  screen and (max-width:555px){
	
	.meeting-list{
		flex-direction:row ;
		flex-wrap:wrap;
		justify-content:center;
	}

	.meeting-card{
		display:flex;
		flex-direction: column;
		padding: 20px 10px;
		margin: 10px;
		max-width: 350px;
		height: 180px;
	}

	.meeting-description{
		/* width:100%; */
		/* text-overflow: ellips/is; */
		/* display:none; */
	}

	.meeting-right-column, .meeting-left-column{
		justify-content: flex-start;
		align-items:center;
		width: 100%;
	}

	.meeting-date{
		flex-basis: 75%;
		align-items: flex-start;

	}
	.meeting-options-div{
		justify-self:flex-end;
		flex-basis: 25%;
	}
}