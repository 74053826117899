.Footer {
  grid-row: 3/4;
  grid-column: 2/3;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  max-width: 1750px;
}

p {
  color: #191919 !important;
}

#endSession {
  margin-top: 1.5em;
}
.timeDisplay {
  grid-row: 1/2;
  grid-column: 2/3;
  align-self: center;
  justify-self: end;
}

#modal-button {
  margin-top: 10px;
}

.remote-video-div {
  padding-left: 5px;
  display: flex;
  flex-direction: row;
}

.pip-btn {
  cursor: pointer;
  outline: 1px solid transparent;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 4px 8px;
  width: 50px;
  justify-self: end;
}

.localButton {
  cursor: pointer;
  padding: 5px;
  padding-right: 70px;
  padding-left: 70px;
  border: #101010 1px solid;
  margin-bottom: 5px;
  color: #802021;
  box-shadow: -5px -5px 1px #c67d7e;
  background: #eceff0;
  border-radius: 5px;
}

.localButton:hover {
  box-shadow: -5px -5px 1px #2c35bd;
  background: #c6e1e9;
}

.localButtonJoin {
  cursor: pointer;
  padding: 5px;
  padding-right: 70px;
  padding-left: 70px;
  border: #101010 1px solid;
  margin-bottom: 5px;
  color: #202021;
  box-shadow: -5px -5px 1px #2c35bd;
  background: #87e9aa;
}

.localButtonJoin:hover {
  box-shadow: -5px -5px 1px #186a35;
  background: #e5f2ea;
}

.roomContainer {
  grid-row: 1/3;
  grid-column: 1/2;

  display: grid;
  grid-template-columns: 50px 1fr 50px;
  grid-template-rows: 50px 1fr 50px;
}

.videoContainer {
  grid-column: 2/3;
  grid-row: 2/3;
  display: grid;
  grid-template-rows: 20px 1fr;
  grid-template-columns: 0.8fr 2fr;
  max-width: 1669px;
  /* justify-content: center; */
  /* justify-items: center; */
  align-self: center;
  justify-self: center;
  grid-gap: 10px;
}

#remoteVideoWrap {
  grid-row: 2/3;
  grid-column: 2/3;
  z-index: 10;
  width: 100%;

  display: flex;
  flex-direction: column;
  position: relative;
  /* grid-template-columns: 1fr;
  grid-template-rows: 1fr auto; */
}

#waitingWarning {
  grid-column: 1/2;
  grid-row: 2/3;
}

#remoteVideoBar {
  background: #7dc693;
}

#pip {
  position: absolute;
  right: 0;
  z-index: 10;
  grid-row: 1/2;
  grid-column: 1/2;
  justify-self: end;
  align-self: flex-start;
  background-color: #7dc69383;
  border-radius: 5px;
}

#remoteVideo {
  justify-self: end;
  grid-row: 1/2;
  grid-column: 1/2;
  z-index: 5;
  /* height: 100%; */
  width: 100%;
  /* min-width: 270px;
    min-height: 209px; */
  max-width: 1175px;
  max-height: 664px;

  /* border-radius: 10px; */
  box-shadow: 5px -3px 1px #7dc693;
  border: #101010 2px solid;
  background-color: #598366;
  border-radius: 5px;
  transform: scaleX(1);
}

#localVideoWrap {
  grid-row: 2/3;
  grid-column: 1/2;
  z-index: 10;
  width: 100%;
}

#localVideo {
  grid-row: 1/2;
  grid-column: 1/2;
  z-index: 10;
  width: 100%;
  /* background-color: black; */
  box-shadow: 5px -3px 1px #c67d7e;
  border: #101010 2px solid;
  border-radius: 5px;

  /* min-width: 270px;
    min-height: 209px; */
  /* height: 100%; */
}

.smallLogo {
  grid-column: 1/2;
  grid-row: 1/2;
  height: 25px;
  justify-self: center;
  align-self: center;
  cursor: pointer;
}

video {
  transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
}

.video_controls_bar {
  width: 20%;
  background-color: lightblue;
  margin-left: 40%;
}

.video_controls_bar span {
  float: left;
  width: 25%;
  padding-left: 2px;
  padding-right: 4px;
}
/* .video_controls_bar span:hover {
  background-color: lightgray;
  
} */

.video_controls_bar #mutebtn:hover,
.video_controls_bar #audio_text {
  display: inline-block;
  /* background-color: rgb(226, 15, 15); */
}

.video_controls_bar #audio_text {
  /* margin-left: 0%;
  width: 100%; */
  display: none;
}
.video_controls_bar #vid_text {
  margin-left: 0%;
  width: 100%;
  display: none;
}

.video_controls_bar #share_text {
  margin-left: 0%;
  width: 100%;
  display: none;
}
