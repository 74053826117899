.chip {
    display: inline-block;
    padding: 5px;
    font-size: 16px;
    border-radius: 25px;
    color: black;
    text-align: center;
    font-family: "Lato";
    /* background-color: #050000; */
  }
  
  .card img {
    padding: 5px 0;    
    height:auto;
    width:120px;
    align-self: center;
    border-radius: 50%;
  }
  .card {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    max-width: 300px;
    margin: auto;
    text-align: center;
    padding: 10px;
    padding-top: 5px;
  }
  .img {
    width: 20px;
    height: 50px;
  }
  
  .title {
    color: grey;
    font-size: 18px;
  }

  .bold{
    font-weight: 600;
  }

  .center{
    text-align: center;
  }

  .profile-name{
    font-size: 1.1rem;
  }