.todolist {
  padding-left: 10px;
  padding-top: 20px;
  padding-right: 10px;
  min-height: 200px;
  max-height: auto;
  max-width: 100%;
  background-color: #ffffff;
  background-size: 40px 40px;
  overflow: hidden;
}

.heading {
  display: flex;
  align-items: flex-start;
  justify-content: start;
}

.heading p {
  margin: 0;
  padding-bottom: 10px;
  border-radius: 10px;
  border-radius: 20% 5% 20% 5%/5% 20% 25% 20%;
  color: #494a4b;
}

.todolist form {
  margin-top: 20px;
}

.todolist input {
  border: 0;
  border-bottom: 1px solid #c4c4c4;
  background-color: transparent;
  font-family: "Architects Daughter", sans-serif;
  font-size: 1rem;
  font-weight: bold;
  padding: 5px 2px;
  width: 60%;
  color: #ffffff;
  /* border-radius: 5px; */
}

input[type="text"] {
  text-align: left;
  color: #000000;
  font-weight: normal;
}

input::placeholder {
  color: #6c6969;
  font-weight: normal;
  text-align: left;
}

.todolist button {
  font-weight: normal;
  color: #4f4f4f;
  border: none;
  margin-left: 10px;
  border-radius: 2px;
  background-color: #dbecf7;
  padding: 3px 20px;
  font-family: "Architects Daughter", sans-serif;
  cursor: pointer;
}

.items {
  margin: 2px 0 0 2px;
  padding: 5px 0;
  display: flex;
  flex-direction: column;
  /* overflow:scroll; */
  /* max-height: 200px; */
}

.itemTimer {
  color: #dbecf7;
}

.todoInput {
  display: flex;
  flex-direction: row;
  padding: 5px;
  justify-content: space-between;
  border-radius: 5px;
  background-color: #f5f5f5;
}

.regular-checkbox{
  font-size: 2rem !important;
  cursor: pointer;
}
.eachItem {
  display: grid;
  grid-template-columns: 0.35fr 1.5fr 0.15fr 0.5fr 0.2fr;
  align-items:center;
  padding-left: 5px;
  padding-right: 5px;
  align-items: center;
  /* background-color: rgba(250, 235, 215, 0.228); */
  margin-bottom: 2px;
  margin-top: 3px;
  border-radius: 10px;
  font-size: 1.2rem;
  /* flex-direction: row; */
  /* align-self: flex-start; */
  /* justify-content: space-around; */
}
.itemTimer {
  align-self: flex-start;
  justify-self: start;
  text-align: start;
  padding: 0 2px; 
}

.items ul {
  max-width: 100%;
  list-style-type: none;
  padding-left: 15px;
}
.items ul li {
  max-width: 98%;
  text-align: left;
  margin: 2px 0px;
  padding: 5px;
  font-size: 1rem;
  font-weight: 400;
  color: #3a3a3b;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.todoTaskText {
  /* color: #fdc66e; */
  text-align: start;
  align-self:center;
  justify-items:center;
  display: flex;
  flex-direction: column;
}

.todoTaskText > p {
  margin: 0 !important;
  padding: 0 !important;
  padding-top:2px !important;

}
.closeButton {
  cursor: pointer;
}

.icons {
  cursor: pointer;
}
