nav {
	position: fixed;
	top:0;
	z-index: 9;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content:space-between;
	background-color: #ffffff;
	margin: 0;
	width: 100vw;
	min-width: 375px;
	height: 60px;
	padding: 5px;
	border-bottom: 0.5px solid rgb(230, 230, 230);
	box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
}

.logo-div{
	display: flex;
	flex-basis: 10%;
	justify-content: flex-start;
	justify-self: start;
	align-items: center;
	margin-left: 10px;
}
.navbar-logo {
	cursor: pointer;
	transition: transform 0.5s ease-in-out;
	transform: scale(1.0);
}

.navbar-logo:hover{
	transition: transform 0.2s ease-in-out;
	transform: scale(1.05);
}

.logo-div > span {
	margin-left: 0.8rem;
	font-weight: 700;
	font-size: 1.4rem;
}
.center-div, .right-div{
	display: flex;
	justify-content: center;
	align-items:center;

}

.icon-container{
	/* border-bottom: 3px rgb(0, 132, 255) solid; */
	width: 100px;
	margin:0 2px;
	cursor:pointer;
	padding: 12.5px 0;
	margin-bottom: 5px;
}

.icon-container > *{
	width: 90px;
	border-radius: 5px;
}

.icon-container > *:hover{
	background-color:#58a6e6;
}
.right-div{
	flex-basis:10%;
}
.user-div{
	display: flex;
	justify-content: center;
	align-items: center;
	margin-right: 0.2rem;
	padding: 5px;
	cursor: pointer;
	border-radius: 5px;
}

.user-div:hover{
	background-color:rgb(245, 245, 245);
}
.arrow-down{
	transition: transform 1s ease-in-out;
	transform: scale(1.0);
}
.user-div:hover > .arrow-down{
	transition: transform 0.4s ease-in-out;
	transform: translate(0px, 4px);
}

.profileImage {
	margin: 0 5px;
	border-radius: 20px;
	border: 0px;
	background: rgb(144, 144, 144);
}

.popover-arrow {
	display: none !important;
}
.popover{
	margin-top: -1px !important;
	background-color: rgba(255, 255, 255, 0.986);
	font-family:"Lato", "Vollkorn";
	padding: 10px;
}

.popover-header{
	background-color: white;
	padding-left: 5px;
	padding-bottom: 10px;
	font-size: 1rem;
	font-weight: 600;
}

.popover-img {
	margin-left: 0;
	padding-left: 0;
}
.popover .arrow {display:none;}

.simple-popover{
	padding: 15px;
	background-color: white;
	font-size: 1rem;
	font-family:"Lato", "Vollkorn";
}

.sign-out-text{
	/* font-family: "Lato"; */
	padding:5px 0 !important;
	border-radius: 5px;
	text-align: center;
	font-weight: 600;
	margin-top: 10px;
	padding: 10px;
}

.sign-out-text:hover{
	background-color: rgb(245, 245, 245);
	cursor: pointer;
	color: rgb(216, 33, 33);
}

.notification-tab{
	padding: 25px;
	/* margin-top: 10px; */
	width: 350px;
	font-family: lato;
	font-size: .9rem;
	color: rgb(195, 195, 195);
	align-self: center;
	text-align:center;
	letter-spacing: .08rem;
	
}