* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  /* outline: 1px solid red; */
  word-break: break-word;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

*::-webkit-scrollbar{
  display: none;
}

html {
  scroll-behavior: smooth;
  width: 100vw;
  background-color: #F5F5F5;
  /* overflow:hidden; */
}

body {
  font-family:  "Vollkorn", "Montserrat", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100vw;
  min-height:100vh;
  /* overflow: hidden; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Custom scroll bar */
::-webkit-scrollbar {
  width: 5px; 
  height: 0px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 10px rgb(255, 255, 255); 
  border-radius: 5px;
  visibility:hidden;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(139, 138, 138); 
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #c3c0c0; 
}

input:focus, textarea:focus, select:focus{
  outline: none;
}

button{
  outline:none !important;
}
