.feed-title{
	/* background-color:white; */
	font-family: "Lato";
	letter-spacing: .05rem;
	font-size: 1.3rem;
	font-weight: 700;
	font-style: bold;
	text-align: left;
	padding: 5px 0;
	border-radius: 5px;
	text-decoration:underline;
	/* box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1); */
}
#feedbackLink {
	position:fixed;
	display:flex;
	align-items:center;
	justify-content:center;
	height: 30px; 
	top: calc(100vh - 30px);
	left:220px;
	font-weight:400;
	/* text-decoration:underline; */
	font-style:italic;
	letter-spacing: 1.5;
	width: 100%;
	padding: 10px;
	cursor: pointer;
	font-size: 1rem;
	color: black;
	font-family: "Lato";
	background-color: #DBECF6;
	animation-name: example; 
	animation-duration: 6s;
	animation-iteration-count: infinite;
	animation-timing-function: ease-in-out;
  }
  
  @keyframes example {
	from {background-color: #DBECF6;}
	to {background-color: white;}
  }