.virtualLibraryWrap {
  background-color: #e5e5e5;
  height: 100vh;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-template-rows: 50px auto 20px;

  /*background-color: blue;*/
}
.topBar {
  grid-column: 1/3;
  grid-row: 1/2;
  background-color: white;

  display: flex;
}
.jitsiWrap {
  grid-column: 2/3;
  grid-row: 2/3;
}

.todolist {
  grid-column: 1/2;
  grid-row: 2/4;
}

#psLogo {
  padding-left: 10px;
}


@media  screen and (max-width:900px){
.todolist{
  display:none;
}

.jitsiWrap {
  grid-column: 1/3;
}

}