@media screen and (max-width:1100px){
	
	.leftColumn{
		min-width: 300px;
		width:300px;
		overflow:auto !important;
	}
	
	.leftColumn{
		padding-left: 0;
	}
	
	.rightColumn{
		width:100vw;
		max-width: 100vw;
		margin-left:300px;
		padding-left: 30px;
		overflow:auto !important;
	} 
	.mainRoomTitle{
		font-size: 2rem;
	}

}
	
@media screen and (max-width:950px){
	#feedbackLink {
		left:0 !important;
		z-index: 999 !important;
	}
	.leftColumn{
		display:none;
	}
	.rightColumn{
		align-items: center; 
		margin-left:0;
		padding-left:0;	
	}
	.homeContainer {
		align-items:center;
		justify-content:center;
	}
	.topRow, .bottomRowSection{
		justify-content: center;
	}

	.bottomRowSection{
		justify-content: center;
		align-items:center;
		text-align: center;

	}
	.roomCardTitle{
		/* text-align: center; */
	}
}
@media screen and (max-width:768px){
	
	
	.mainRoomTitle{
		align-self: center;
		text-align: center;
		padding: 0 20px;
	}
	
	.groupSection{
		width:300px;
	}
	.groupSection, .meetings{
		margin-right:0;
		/* margin-bottom:0; */
	}
	.meetings{
		min-width: 90vw;
	}
	.bottomRowSection, .classes,.bottomRowTitle, .classSection, .meetings, .meeting-list, .meeting-card{
		width: 95vw !important;
		text-align:center;
		margin:0;
	}

	.classSection{
		margin: 10px;
	}
	
}

@media screen and (max-width:600px){
#feedbackLink {
	height: 70px !important; 
	top: calc(100vh - 70px) !important;
}
}