.modal {
  display: block;
  position: fixed;
  z-index: 10;
  padding-top: 50px;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  overflow: scroll;
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.6); /* Black w/ opacity */
}

.modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 0;
  display: flex;
  flex-direction: column;
  border: 1px solid #888;
  border-radius: 5px;
  width: 500px;
  min-height: 500px;
  /* width: 60%; */
  font-family: "Lato", "Helvetica";
  font-style: none !important;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 1s;
  animation-name: animatetop;
  animation-duration: 1s;
}

@-webkit-keyframes animatetop {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes animatetop {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.modal-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 25px;
  padding: 5px 16px;
  background-color: #ffffff;
  color: rgb(0, 0, 0);
  text-align: center;
  justify-content: center;
  border-bottom: 0px solid rgb(216, 216, 216);
  
}

.modal-header > * {
  padding: 5px;
}
.modal-body {
  /* position: relative; */
  right: 2px;
  padding: 5px 35px;
  height: 100%;
  font-family: Helvetica;
  font-size: 16px;
  /* font-style: italic; */
  line-height: 21px;
  /* display: grid; */

  /* justify-items: center; */
}

.modal-footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  justify-content: center;
  padding: 15px 16px;
  background-color: #ffffff;
  color: black;
  border-top: 1px solid rgb(216, 216, 216);
  justify-content: space-between;
}

.disagree-div,
.agree-div {
  flex-basis: 30%;
  display: inline-block;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.disagree-div{
  margin-left: 50px;
}
.agree-div{
  margin-right: 50px;
}
.disagree-button,
.agree-button {
  background-color: #0073d0;
  color: white;
  font-family: "Lato";
  font-size: 14px;
  letter-spacing: 2px;
  line-height: 14px;
  max-width: 200px;
  min-height: 40px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  width: 100%;
  border:none;
}

.disagree-button {
  border: 1px solid #0073d0;
  background-color: white;
  color: #0073d0;
}

.disagree-button:hover {
  background-color: #dd2727;
  border: 1px solid #dd2727;
  color: white;
}

.agree-button{
  margin-right: 45px;
}
.agree-button:hover {
  background-color: #002984;
  border-color: #002984;
}


.svg-container {
  width: 100%;
  height: 100%;
  margin: 0;
  align-items: center;
  padding: 0;
  overflow: hidden;
}

.svg-container svg {
  width: 100%;
  height: 100%;
}

.BTNA,
#word {
  cursor: pointer;
}

#youtube {
  display: flex;
  flex-direction: row;
  justify-self: center;
  padding: 35px;
}

.modal-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
}

#panda {
  cursor: pointer;
}

.panda-div {
  display: flex;
  flex-direction: column;
}
