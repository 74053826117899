.top-home-container{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-bottom: 0;
  margin-bottom:0;
}

.homeContainer {
  display: flex;
	flex-direction: row;
  width: 100vw;
  margin-top: 50px;
  margin-bottom: 0;
  padding: 0;
  align-items: flex-start;
  justify-content:flex-start;
}

.leftColumn {
  width: 400px;
  position: fixed;
  top:50px;
  display: flex;
  flex-direction: column;
  text-align: left;
  background-color: white;
  height:100%;
  padding:20px;
  margin-right:0;
  
  z-index:4;
  overflow: hidden;
  height: 100vh;
  border-right: 1px solid rgb(230, 230, 230);
}

.rightColumn{
  padding: 60px 0 30px 120px;
  margin-left: 400px;
  margin-bottom: 0;
  width: calc(100vw - 400px);
  display: flex;
  flex-direction: column;
  height: 90vh;
  overflow:hidden;
}
.rightColumn::-webkit-scrollbar, .leftColumn::-webkit-scrollbar{
  display: initial;
}

.rightColumn:hover, .leftColumn:hover{
  overflow: auto;
  -ms-overflow-style: initial;
  scrollbar-width: initial;
}
.joinRoomCard{
  width: 305px;
  margin-top: 10px;
  padding: 20px 15px;
  border-bottom: 1.5px solid rgb(230, 230, 230);
}

.roomCardTitle{
  font-size: 1.8rem;
  font-weight: 600;
  padding-bottom:10px;
}
.roomCardBody{
  background-color: #F5F5F5;
  min-height: 40px;
  width: 100%;
  border-radius: 5px;
  display: flex;
  flex-direction:row;
  min-width: 120px;
  font-family: "Lato";
  font-size: 1.1rem;
  letter-spacing: 1px;
  padding: 7.5px;
  align-items: center;
}
#remoteRoomIDInput{
  background-color: #F5F5F5;
  color: #000000;
  flex-basis: 70%;
  /* min-width: 120px;/ */
  border: none; 
  border-bottom: 1px solid #b4b4b4;
  margin:0 10px;
  height: 37.5px;
}
.cardButton{
  display: flex;
  background-color: #DBECF6;
  color: #000000;
  min-height: 40px;
  min-width: 50px;
  width: 25%;
  height: 100%;
  border-radius: 4px;
  align-items: center;
  font-weight: 350;
  transform: scale(1);
  transition: transform 0.5s ease-in-out;
}

.cardButton:hover{
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  transform: scale(1.5);
  transition: transform 0.5s ease-in-out;

}

.cardButton > span{
  font-size: 1rem;
  flex-basis: 100%;
  text-align:center;
  cursor: pointer;
}

.warning{
  padding: 5px; 
  font-family: "Lato";
  color: rgb(255, 83, 83) !important;
}

.myRoomId {
  color: #000000;
  font-size: 1.1rem;
  flex-basis: 70%;
  min-width: 120px;
  margin:0 10px;
  display:flex;
  align-items: center;
}

.joinPersonalRoomButton{
  margin: 0;
  height: 40px;
  width: 100px;
  text-align: center;
  font-size: 1.2rem;
  background-color: #DCF6E6;
}
.joinRoomButton{
  background-color: #DCF6E6;
}
.joinPersonalRoomButton:hover {
  transform: scale(1.05);
  transition: transform 200ms ease-in-out;
}

.joinPersonalRoomButton > span{
  flex-basis: 100%;
  font-family: "Lato";
}

.copyToClipboard{
  margin:0;
  color: rgb(0, 0, 0);
  cursor: pointer;
  display:flex;
}
.copyToClipboard > span{
  flex-basis:100%;
  text-align: center;
}
.statsCard{
  border: none;
  height: 550px;
}
.WeeklyStats{
  display: flex;
  flex-direction: column;
  align-items: center;
}


.topRow, .bottomRow{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  /* justify-items:space-between; */
  width: 100vw;
  max-width: 800px;
}
.topRow{
  justify-items: space-between;
}
.mainRoomTitle{
  font-size: 2.5rem;
  color: black;
  padding: 5px 0;
  text-align:left;
}


.groupSection{
  background-color: white;
  width: 320px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 20px;
  margin: 15px 12.5px;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1)
}

#groupSectionFirst{
  margin-left:0;
}

.groupSection > *{
  padding: 5px;
}

.roomCardTitle {
  text-align: left;
  font-size: 1.5rem;
  width: 100%;
}

.libraryDescription{
  text-align: left;
  font-family: "Lato";
  font-weight: 300;
}

.mainRoomButton{
  padding: 10px 0;
  margin: 0;
}

.bottomRow{
  display:flex;
  flex-direction: column;
}
.bottomRowSection{
  margin-bottom: 15px;
  margin-top: 10px; 
  display: flex;
  flex-direction:column;
}
.bottomRowTitle{
  padding: 10px 0;
}

.classes{
  display: flex;
  flex-direction: column;
  width: 665px;

}

.classSection{
  background-color: white;
	padding: 10px;
	margin: 0;
  display: flex;
  flex-direction: row;
  justify-items: flex-end;
  align-items:center;
  width: 665px;
  border-radius: 5px;
}

.publicRoom{
  display: flex;
  flex-direction:row;
  justify-items: center; 
  align-items: center;
}                                                  

.classTitle{
  flex-basis: 55%;
  text-align:left;
  font-size: 1.2rem;
  font-weight: 600;
  margin-left:10px;
}

.classJoinButton{
  flex-basis: 15%;
  align-self: flex-end;
  justify-self: flex-end;
}

.classJoinButton:hover{
  color: black;
}

.classDescription{
  flex-basis: 25%;
  text-align:left;
  font-family: "Lato";
  font-size: 0.8rem;
  font-weight: 300;
  padding: 0 8px;
  display: flex;
  flex-direction:row; 
  align-items: center;
  justify-items: flex-end;
}

.class-options{
  flex-basis: 5%;
  cursor: pointer;
  align-self: flex-end;
  justify-self: flex-end;
}
.warningLoginPage{
  padding: 15px;
  width: 250px;
  height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  justify-self: center;
  align-items: center;
  background: white;
  border-radius: 5px;
}

.online-signal{
  height: 8px;
  width: 8px;
  background-color: #99D6B1;
  border-radius: 4px;
  margin-right:5px;
  margin-left: 15px;
  /* flex-basis: 5%; */
}

#feedbackLink {
  position:fixed;
  display:flex;
  align-items:center;
  justify-content:center;
  height: 30px; 
  top: calc(100vh - 30px);
  left:220px;
  font-weight:400;
  /* text-decoration:underline; */
  font-style:italic;
  letter-spacing: 1.5;
  width: 100%;
  padding: 10px;
  cursor: pointer;
  font-size: 1rem;
  color: black;
  font-family: "Lato";
  background-color: #DBECF6;
  animation-name: example; 
  animation-duration: 6s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

@keyframes example {
  from {background-color: #DBECF6;}
  to {background-color: white;}
}