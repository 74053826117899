.chat-room-container {
  display: flex;
  flex-direction: column;
  margin: 16px;
  padding: 2px 5px;
  height: 200px;
  box-shadow: -5px -5px 1px #834a88;
  background: #eceff0;
  border-radius: 5px;
}

.room-name {
  margin-top: 0;
}

.messages-container {
  flex: 1;
  min-height: 100px;
  scroll-behavior: auto;
  overflow: scroll;
  border: 1px solid black;
  border-radius: 7px 7px 0 0;
}

.send-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* grid-template-columns: 1fr 0.2fr; */
}

.messages-list {
  list-style-type: none;
  padding: 0;
}

.new-message-input-field {
  height: 80px;
  max-height: 2em;
  font-size: 1em;
  width: 100%;
  padding-left: 10px;
  padding-top: 5px;
  /* padding: 8px 20px; */
  /* padding-top: 0.8em; */
  background-color: #f2e1f4;
  border-radius: 10px;
  border: none;
  resize: none;
  /* line-height: 80px; */
}

textarea::placeholder {
  /* Firefox, Chrome, Opera */
  /* line-height: 80px; */
  text-align: start;
}

.message-item {
  width: 70%;
  margin: 8px;
  padding: 5px 10px;
  word-break: break-word;
  border-radius: 5px;
  color: white;
  text-align: start;
}

.my-message {
  background-color: #c67d7e;
  margin-left: auto;
}

.received-message {
  background-color: #78bb8c;
  margin-right: auto;
}

.send-message-button {
  margin: 10px;
  font-size: 1em;
  font-weight: bold;
  color: white;
  background: #834a88;
  width: 2em;
  height: 2em;
  text-align: center;

  border-radius: 50px;
  border: none;
}

.messages-container,
.new-message-input-field,
.send-message-button {
  border-color: #9a9a9a;
}
