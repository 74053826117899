.home {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50%;
    height: 50%;
}
.welcome {
    /* background-color: yellow;*/
    width: 90%;
    margin: 5% 5% 5% 5%;
    text-align: center;
}

.logon {
    /*background-color: indianred;*/
    /* width: 50%; */
    margin: 20% 25% 20% 15%;
    padding-left: 25%;
    align-content: center;
}

button{
    outline: none;
}

.bsk-container {
    font-family: "Lato";
    margin: 10px auto;
    padding-right: 15px;
    padding-left: 15px;
    border-radius: 5px;
    border: 0.5px solid rgb(206, 206, 206);
    text-align: left;
    display: flex;
    align-items: center;

}

.bsk-container:hover {
    box-shadow: 0px 1px 1px rgb(134, 176, 255);
    transform: scale(1.05);
}

.signPrompt{
    font-family: "Lato";
    font-weight: 300;
    font-size: 1.2rem;
}
.ms-icon {
    height: 1em;
    width: 1em;
    top: .125em;
    position: relative;
}

.bsk-btn-default {
    color: #333;
    background-color: rgb(255, 255, 255);
    border-color: #eee;
}

.bsk-btn {
    display: inline-block;
    padding: 10px 22px;
    font-size: 16px;
    font-weight: normal;
    line-height: 1.42857;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 0;
}

.login-title{
    font-weight: 700;
    font-family: "Lato";
    font-size:1.3rem;
    padding-bottom: 15px;

}

/* .ms-alt {

} */
