@import "node_modules/react-modal-video/scss/modal-video.scss";
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400&family=Vollkorn&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Vollkorn:wght@700&display=swap');


#root {
  min-width: 100vw;
  min-height: 100vh;
  z-index: 0;
  background-color: #F5F5F5;

}
.App {
  text-align: center;
  min-width: 100vw;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-items: center;
  justify-content: center;

}

.container {
  display:flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 500px;
}

.container > img {
  height: 200px;
  width: 200px;
}

.mission {
  padding-top: 2px;
}

.loginPage {
  border-radius: 20px;
  box-shadow: 0px 1px 1px #484d4e;
  background: #ffffff;
  padding: 20px 0;
  // padding-right: 40px;
  margin-top: 10px;
  border-radius: 3px;
  display:flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  height: auto;
  max-width: 500px;
  
}

.loginBox{
  padding: 0 30px;
  margin: 0;
}

#back {
  display:flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 10px;
  margin-top : 0;
  margin-bottom:10px;

}

#back > *{
  cursor: pointer;
  align-self: flex-end;
  justify-self: flex-end;
  text-align: end;
  // justify-content: right;
}

#arrow {
  width: 40px;
  margin-left: 0;
  padding-right: 20px;
  padding-bottom: 10px;
  /* background-color: red; */
}

#login-button {
  display: flex;
  justify-content: center;
  /* padding-top: 10px; */
  padding-bottom: 10px;
  border-radius: 3px;
}

.bigLogo {
  height: 210px;
  justify-self: center;
  align-self: center;
  /* text-shadow: -7px -7px 21px #0f5faf; */
}

.landingSigning {
  justify-self: start;
}

.landingSigning a {
  text-decoration: none;
}

.signOutButton {
  cursor: pointer;
  padding: 5px;
  padding-right: 70px;
  padding-left: 70px;
  border-radius: 10px;
  margin-bottom: 5px;
  background: #eceff0;
  border-radius: 3px;
}

.signOutButton:hover {
  cursor: pointer;
  padding: 5px;
  padding-right: 70px;
  padding-left: 70px;
  border-radius: 10px;
  background: #9a9494;
}

.p {
  margin: 0;
  margin-top: 5px;
}

.messageSigning {
  grid-column: 2/3;
  grid-row: 3/4;
  text-align: start;
}
